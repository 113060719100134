@import '../../util//variables';

.youtube-player {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.63) 0px 5px 15px;
  border-radius: $border-radius-2;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
    // display: block;
    left: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    height: auto;
    cursor: pointer;
    -webkit-transition: 0.4s all;
    -moz-transition: 0.4s all;
    transition: 0.4s all;

    &:hover {
      -webkit-filter: brightness(75%);
      filter: brightness(75%);
    }
  }

  .play {
    height: 72px;
    width: 72px;
    left: 50%;
    top: 50%;
    margin-left: -36px;
    margin-top: -36px;
    position: absolute;
    background: url('../../../assets/images/play.png') no-repeat;
    cursor: pointer;
  }

  .spinner-border,
  p {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
    color: $color-primary-1;
  }
}
