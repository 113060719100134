.privacy-policy {
  padding: 4rem 0;
  min-height: 100vh;
  background-color: #1a1a1a;
  color: #fff;

  &__content {
    max-width: 800px;
    margin: 0 auto;
    
    h1 {
      font-size: 2.5rem;
      margin-bottom: 1rem;
      color: #FF5403;
    }

    .last-updated {
      color: #888;
      font-style: italic;
      margin-bottom: 2rem;
    }

    section {
      margin-bottom: 3rem;

      h2 {
        color: #FF5403;
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }

      h3 {
        color: #fff;
        font-size: 1.4rem;
        margin: 1.5rem 0 1rem;
      }

      p {
        color: #ddd;
        line-height: 1.6;
        margin-bottom: 1rem;
      }

      ul {
        list-style-type: disc;
        margin-left: 1.5rem;
        margin-bottom: 1rem;

        li {
          color: #ddd;
          margin-bottom: 0.5rem;
          line-height: 1.6;
        }
      }
    }

    a {
      color: #FF5403;
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 768px) {
  .privacy-policy {
    padding: 2rem 1rem;

    &__content {
      h1 {
        font-size: 2rem;
      }

      h2 {
        font-size: 1.5rem;
      }

      h3 {
        font-size: 1.2rem;
      }
    }
  }
}